<template>
  <div
    class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
  >
    <!-- モバイルデバイスの簡易表示 -->
    <div class="flex flex-1 justify-between sm:hidden">
      <a
        href="#"
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        @click="prevPage"
        >Prev</a
      >
      <a
        href="#"
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        @click="nextPage"
        >Next</a
      >
    </div>
    <!-- 横幅の大きな画面用のコンテナ -->
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <!-- 総ページ数、現在の表示位置 -->
      <div>
        <p class="text-sm text-gray-700">
          Showing
          {{ " " }}
          <span class="font-medium">{{
            (currentPage - 1) * pageLimit + 1
          }}</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">{{
            (currentPage - 1) * pageLimit + currentItemsCount
          }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ totalItemsCount }}</span>
          {{ " " }}
          results
        </p>
      </div>
      <!-- ページ切り替えボタン -->
      <div>
        <nav
          class="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <a
            href="#"
            :class="[
              'rounded-l-md',
              pageLinkCommonClass,
              firstPageSelected ? pageLinkDisabledClass : pageLinkDefaultClass,
            ]"
            :disabled="firstPageSelected"
            @click="prevPage"
            @keyup.enter="prevPage"
          >
            <span class="sr-only">Prev</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </a>
          <template v-for="page in pages" :key="page.content">
            <span
              v-if="page.breakView"
              class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
            >
              ...
            </span>
            <a
              v-else-if="page.disabled"
              :class="[pageLinkCommonClass, pageLinkDisabledClass]"
              tabindex="0"
            >
              {{ page.content }}
            </a>
            <a
              v-else
              href="#"
              :class="[
                pageLinkCommonClass,
                page.selected ? pageLinkCurrentClass : pageLinkDefaultClass,
              ]"
              tabindex="0"
              @click="handlePageSelected(page.index + 1)"
              @keyup.enter="handlePageSelected(page.index + 1)"
            >
              {{ page.content }}
            </a>
          </template>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <a
            href="#"
            :class="[
              'rounded-r-md',
              pageLinkCommonClass,
              lastPageSelected ? pageLinkDisabledClass : pageLinkDefaultClass,
            ]"
            @click="nextPage"
            @keyup.enter="nextPage"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { ref, computed } from "vue";

const props = defineProps({
  currentPage: { type: Number, default: 0 },
  currentItemsCount: { type: Number, default: 0 },
  totalItemsCount: { type: Number, default: 0 },
  totalPageCount: { type: Number, default: 0 },
  clickHandler: { type: Function, default: () => {} },
  pageRange: { type: Number, default: 3 },
  pageLimit: { type: Number, default: 20 },
  marginPages: { type: Number, default: 1 },
});

const pageLinkCommonClass =
  "relative inline-flex items-center border px-2 py-2 text-sm font-medium focus:z-20";
const pageLinkDisabledClass = "text-gray-300";
const pageLinkDefaultClass =
  "bg-white border-gray-300 text-gray-500 hover:bg-gray-50";
const pageLinkCurrentClass =
  "z-10 bg-indigo-50 border-indigo-500 text-indigo-600";

const innerCurrentPage = ref(1);

const selected = computed({
  get: function () {
    return props.currentPage || innerCurrentPage.value;
  },
  set: function (newValue) {
    innerCurrentPage.value = newValue;
  },
});

const firstPageSelected = computed({
  get: function () {
    return selected.value === 1;
  },
});

const lastPageSelected = computed({
  get: function () {
    return (
      selected.value === props.totalPageCount || props.totalPageCount === 0
    );
  },
});

const pages = computed(function () {
  let items = {};
  if (props.totalPageCount <= props.pageRange) {
    for (let index = 0; index < props.totalPageCount; index++) {
      let page = {
        index: index,
        content: index + 1,
        selected: index === selected.value - 1,
      };
      items[index] = page;
    }
  } else {
    const halfPageRange = Math.floor(props.pageRange / 2);
    let setPageItem = (index) => {
      let page = {
        index: index,
        content: index + 1,
        selected: index === selected.value - 1,
      };
      items[index] = page;
    };
    let setBreakView = (index) => {
      let breakView = {
        disabled: true,
        breakView: true,
      };
      items[index] = breakView;
    };
    // 1st - loop thru low end of margin pages
    for (let i = 0; i < props.marginPages; i++) {
      setPageItem(i);
    }
    // 2nd - loop thru selected range
    let selectedRangeLow = 0;
    if (selected.value - halfPageRange > 0) {
      selectedRangeLow = selected.value - 1 - halfPageRange;
    }
    let selectedRangeHigh = selectedRangeLow + props.pageRange - 1;
    if (selectedRangeHigh >= props.totalPageCount) {
      selectedRangeHigh = props.totalPageCount - 1;
      selectedRangeLow = selectedRangeHigh - props.pageRange + 1;
    }
    for (
      let i = selectedRangeLow;
      i <= selectedRangeHigh && i <= props.totalPageCount - 1;
      i++
    ) {
      setPageItem(i);
    }
    // Check if there is breakView in the left of selected range
    if (selectedRangeLow > props.marginPages) {
      setBreakView(selectedRangeLow - 1);
    }
    // Check if there is breakView in the right of selected range
    if (selectedRangeHigh + 1 < props.totalPageCount - props.marginPages) {
      setBreakView(selectedRangeHigh + 1);
    }
    // 3rd - loop thru high end of margin pages
    for (
      let i = props.totalPageCount - 1;
      i >= props.totalPageCount - props.marginPages;
      i--
    ) {
      setPageItem(i);
    }
  }
  return items;
});

function prevPage() {
  if (selected.value <= 1) return;
  handlePageSelected(selected.value - 1);
}

function nextPage() {
  if (selected.value >= props.totalPageCount) return;
  handlePageSelected(selected.value + 1);
}

function handlePageSelected(value) {
  if (selected.value === value) return;
  innerCurrentPage.value = value;
  props.clickHandler(value);
}
</script>
